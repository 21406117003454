import React from 'react'
import { Col, Layout } from 'antd'
import Slider from 'react-slick'
import Banner1 from '../../assets/images/home-banners/new-home-banner.jpg'

const { Content } = Layout

function NextBtnStyle(props) {
  const { onClick } = props
  return (
    <div className='react-slick-custom-btn-right' onClick={onClick}>
      <i className='right' />
    </div>
  )
}

function PrevBtnStyle(props) {
  const { onClick } = props
  return (
    <div className='react-slick-custom-btn-left' onClick={onClick}>
      <i className='left' />
    </div>
  )
}

export default function Banner() {
  const bannerSliderSettings = {
    className: '',
    dots: false,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 6000,
    cssEase: 'linear'
  }
  const testSliderSettings = {
    dots: true,
    infinite: true,
    fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: true,
    autoplay: true,
    nextArrow: <NextBtnStyle />,
    prevArrow: <PrevBtnStyle />
  }
  return (
    <Layout>
      <Content>
        <div className='banner-container'>
          <div className='banner-title'>
            <Col lg={12} md={19} sm={18} className='banner-slider-text'>
              <h3 className='animated fadeIn' style={{ marginBottom: 0 }}>Your Consistent Partner in Baking Success!</h3>
              <Slider {...testSliderSettings}>
                <div>
                  <div style={{ margin: '10px', maxWidth: '500px' }}>
                    <h4 className='animated fadeIn'>Who We Are</h4>
                    <p className='animated fadeIn'>The URC Flour Division is one of the top flour millers in the Philippines. With a total of 4 plants, our state-of-the-art flour mills and blending facilities allow us to deliver consistent quality products that fit the specific needs of our customers.</p>
                  </div>
                </div>
                <div>
                  <div style={{ margin: '10px' }}>
                    <h4>How We Work</h4>
                    <p>Our flour plants produce high quality flour, which undergo a standard milling process to ensure that every order of our customer delivers consistent quality.</p>
                  </div>
                </div>
                <div>
                  <div style={{ margin: '10px' }}>
                    <h4>Where We Are</h4>
                    <p>Our state-of-the-art flour mills and blending facilities are found in Manila,
                      Sariaya Quezon, and Davao. The URC Flour Division serves its customers
                      around the country</p>
                  </div>
                </div>
              </Slider>
            </Col>
          </div>
          <Slider {...bannerSliderSettings} arrows={false}>
            <div style={{ position: 'relative' }}>
              <img src={Banner1} alt='banner-1' style={{ height: '900px', width: '100%', objectFit: 'cover' }} />
            </div>
          </Slider>
        </div>
      </Content >
    </Layout >
  )
}
